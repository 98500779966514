import { React, useState } from "react";
import "./header.css";

import { FaFacebook } from "react-icons/fa6";
import { BiLogoGmail } from "react-icons/bi";
import { IoLogoWhatsapp } from "react-icons/io";
import { CiMenuBurger } from "react-icons/ci";

// React Router

const Header = () => {
  const [view, setview] = useState(false);

  function toggleView() {
    const nav = document.querySelector("nav ul");
    setview((view) => !view);
    if (view) {
      nav.style.cssText = "display:flex !important;";
    } else {
      nav.style.cssText = "display:none !important;";
    }
  }
  function facebook() {
    window.open("https://www.facebook.com/titiza.el.100", "_blank");
  }
  function whatsapp() {
    window.open("https://wa.me/0691145767", "_blank");
  }
  return (
    <div>
      <div className="header">
        <div className="container">
          <div className="logo">
            <span>Forever </span> Living Products
          </div>
          <div className="nav-icon">
            <nav>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="#Product">Products</a>
                </li>
                <li>
                  <a href="#Latest">Latest</a>
                </li>
              </ul>
            </nav>
            <CiMenuBurger className="burger-icon" onClick={toggleView} />
            <div className="icons">
              <a onClick={facebook}>
                <FaFacebook />
              </a>
              <a href="mailto:abderazakelhasnaoui22@gmail.com?subjict=contact">
                <BiLogoGmail />
              </a>
              <a onClick={whatsapp}>
                <IoLogoWhatsapp />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
