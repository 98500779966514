// import { useState } from "react";
import "./App.css";

// React Router
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Footer from "./componants/footer/footer";
import Header from "./componants/header/header";
import Hero from "./componants/hero/hero";
// import Latest from "./componants/latest/latest";
// import Products from "./componants/products/products";
// import Ads from "./componants/ads/ads";
import Product1 from "./componants/productPage/product1";
import Product2 from "./componants/productPage/product2";
import Product3 from "./componants/productPage/product3";
import Product4 from "./componants/productPage/product4";
import Product5 from "./componants/productPage/product5";
import Product6 from "./componants/productPage/product6";

function App() {
  // const [display, setdisplay] = useState(true);

  return (
    // <Hero />
    <>
      <Header />
      <Router>
        <Routes className="Products">
          <Route path="/" element={<Hero />} />

          <Route path="/product1" element={<Product1 />} />
          <Route path="/product2" element={<Product2 />} />
          <Route path="/product3" element={<Product3 />} />
          <Route path="/product4" element={<Product4 />} />
          <Route path="/product5" element={<Product5 />} />
          <Route path="/product6" element={<Product6 />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
