import React, { useRef, useState } from "react";
import "./products.css";

import emailjs from "@emailjs/browser";

import product1 from "../../assets/مرطب الشفاه1.jpg";
import product2 from "../../assets/مرطب الشفاه2.jpg";
import product3 from "../../assets/مرطب الشفاه3.webp";
import ImageSelector from "./ImageSelector";

const Product4 = () => {
  const form = useRef();
  const [mainImg, setMainImg] = useState(product2);

  const [display, setDisplay] = useState(true);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_gyexboi",
        "template_nhbc0am",
        form.current,
        "gTw-7f1S50UtAjkgA"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setDisplay(false);
  };

  return (
    <div className="products container">
      <div className="holder ">
        <div className="gallery">
          <div className="main--vue">
            <img src={mainImg} alt="" />
          </div>
          <div className="imgs">
            <ImageSelector setMainImg={setMainImg} imageSource={product1} />
            <ImageSelector setMainImg={setMainImg} imageSource={product2} />
            <ImageSelector setMainImg={setMainImg} imageSource={product3} />
          </div>
        </div>
        <div className="product--details">
          <h1>مرطب الشفاه</h1>
          <div>
            أغذِ شفتيك واحميهما مع Forever Aloe Lips✨. معزز بجمال الألوة فيرا
            الطبيعي🌱، يوفر هذا مرطب الشفاه ترطيبًا دائمًا ولمسة ملطفة🥰. سواء
            كنت تواجه ظروفًا جوية قاسية💢أو تبحث ببساطة عن الحفاظ على شفاه ناعمة
            ومتجددة🌟، فإن صيغتنا تغطيك. حجمها المدمج يجعلها الرفيق المثالي
            لتوفير الرطوبة والعناية أثناء التنقل👄. وداعاً للشفاه الجافة
            والمتشققة، واستقبل الشفاه المتجددة والمحبوبة💋. ارتق بروتين العناية
            بالشفاه مع Forever Aloe Lips - لأن ابتسامتك تستحق الأفضل!
          </div>
          <div className="price">
            55
            <span>Dh</span>
          </div>
        </div>
      </div>
      <div className="order">
        {display ? (
          <h2>للطلب أدخل معلوماتك هنا !</h2>
        ) : (
          <div className="order--done">
            <h2>لقد تلقينا طلبك ستصلك رسالة قريبا!</h2>
            <p>ارتح</p>
          </div>
        )}
        {display && (
          <form ref={form} onSubmit={sendEmail}>
            <input
              className="Product--title"
              name="product_name"
              type="text"
              value="مرطب الشفاه"
            />
            <label>الإسم الكامل</label>
            <input type="text" name="user_name" />
            <label>رقم الهاتف :</label>
            <input type="number" name="user_number" />
            <label>البريد الإلكتروني :</label>
            <input type="text" name="email" />
            <label>العنوان</label>
            <input type="text" name="address" />
            <label>الكمية</label>
            <input type="number" name="quantity" defaultValue={1} />
            <label>رسالة :</label>
            <textarea name="message" />
            <input className="submit" type="submit" value="Send" />
          </form>
        )}
      </div>
    </div>
  );
};

export default Product4;
