import React from "react";
import "./ads.css";

import payment from "../../assets/payment.png";
import delivery from "../../assets/delivery.png";
import technicalSupport from "../../assets/technical-support.png";

// import { Autoplay } from "swiper/modules";

// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/swiper-bundle.css";

// const breakPoint = {
//   1199: {
//     slidesPerView: 3,
//   },
//   767: {
//     slidesPerView: 2,
//   },
//   320: {
//     slidesPerView: 1,
//   },
// };
const Ads = () => {
  return (
    <div className="ads ">
      <div className="container">
        <div className="box">
          <h4>الدفع عند الإستلام</h4>
          <img src={payment} alt="" />
        </div>
        <div className="box">
          <h4>التوصيل بالمجان</h4>
          <img src={delivery} alt="" />
        </div>
        <div className="box">
          <h4>خدمات ما بعد البيع</h4>
          <img src={technicalSupport} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Ads;
