import { React } from "react";
import "./hero.css";
import { Autoplay } from "swiper/modules";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/swiper-bundle.css";

// images
import product1_1 from "../../assets/معجون الأسنان 1.jpg";
import product1_2 from "../../assets/معجون الأسنان 2.webp";
import product2_1 from "../../assets/مزيل العرق 1.jpg";
import product2_2 from "../../assets/مزيل العرق 2.jpg";
import product3_1 from "../../assets/واقي شمسي 1.jpg";
import product3_2 from "../../assets/واقي شمسي 2.jpg";

// icons
import { FaFacebook } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { BiLogoGmail } from "react-icons/bi";

// componants
import Products from "../products/products";
import Ads from "../ads/ads";
import Latest from "../latest/latest";

// staticData
const data = [
  {
    title: "معجون الأسنان",
    image1: product1_1,
    image2: product1_2,
    color: "red",
    route: "/product1",
  },
  {
    title: "مزيل العرق",
    image1: product2_1,
    image2: product2_2,
    color: "blue",
    route: "/product2",
  },
  {
    title: "واقي شمسي",
    image1: product3_1,
    image2: product3_2,
    color: "yellow",
    route: "/product3",
  },
];

const Hero = () => {
  function facebook() {
    window.open("https://www.facebook.com/titiza.el.100", "_blank");
  }
  function whatsapp() {
    window.open("https://wa.me/0691145767", "_blank");
  }
  return (
    <div className="landing">
      <div className="container">
        <Swiper
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          loop={true} // To enable looping
          spaceBetween={50}
          slidesPerView={1}
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          {data.map((e) => {
            return (
              <SwiperSlide>
                <div className="content" id={e.color}>
                  <div className="holder">
                    <h2>{e.title}</h2>
                    <a href={e.route} className="more">
                      For More Information
                    </a>
                    <div className="icon">
                      <a onClick={facebook}>
                        <FaFacebook />
                      </a>
                      <a href="mailto:abderazakelhasnaoui22@gmail.com?subjict=contact">
                        <BiLogoGmail />
                      </a>
                      <a onClick={whatsapp}>
                        <IoLogoWhatsapp />
                      </a>
                    </div>
                  </div>
                  <div className="imgs">
                    <img src={e.image1} alt="product" />
                    <img src={e.image2} alt="product" />
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <Products />
      <Ads />
      <Latest />
    </div>
  );
};

export default Hero;
