import React from "react";

const ImageSelector = ({ setMainImg, imageSource }) => {
  const setImage = () => {
    setMainImg(imageSource);
  };

  return (
    <div>
      {/* Render your image */}
      <img src={imageSource} alt={`Product`} onClick={setImage} />
    </div>
  );
};

export default ImageSelector;
