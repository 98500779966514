import React from "react";
import "./footer.css";

import { FaFacebook, FaSquareWhatsapp } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";

const Footer = () => {
  function facebook() {
    window.open("https://www.facebook.com/titiza.el.100", "_blank");
  }
  function whatsapp() {
    window.open("https://wa.me/0694400453", "_blank");
  }
  return (
    <div className="footer">
      <div className="container">
        <div className="box1">
          <div className="logo">
            <span>E</span>-Shop
          </div>
          <div className="icon">
            <FaFacebook />
            <FaSquareWhatsapp />
            <RiInstagramFill />
          </div>
        </div>
        <div className="box2">
          <h2>SHOP</h2>
          <div className="links">
            <a href="#Products">Products</a>
            <a href="#">Location</a>
          </div>
        </div>
        <div className="box2">
          <h2>LINKS</h2>
          <div className="links">
            <a onClick={facebook}>Facebook</a>
            <a onClick={whatsapp}>Whatsapp</a>
          </div>
        </div>
        <div className="box2">
          <h2>Contact</h2>
          <div className="links">
            <a
              className="mail"
              href="mailto:abderazakelhasnaoui22@gmail.com?subjict=contact"
            >
              abderazakelhasnaoui22@gmail.com
            </a>
            <a onClick={whatsapp}>+212694400453</a>
            <a href="#">Marrakech</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
