import React from "react";
import "./latest.css";
import productImg from "../../assets/معجون الأسنان 1.jpg";

const Latest = () => {
  return (
    <div className="latest" id="Latest">
      <div className="container">
        <div className="text">
          <h2>معجون الأسنان</h2>
          <p>معجون أسنان خال من الفلور و غري كاشط</p>
          <a href="/product1">اشتري الان</a>
        </div>
        <div className="image">
          <img src={productImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Latest;
