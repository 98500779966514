import React from "react";
import "./products.css";

import product1 from "../../assets/معجون الأسنان 1.jpg";
import product2 from "../../assets/مزيل العرق 1.jpg";
import product3 from "../../assets/واقي شمسي 1.jpg";
import product4 from "../../assets/مرطب الشفاه1.jpg";
import product5 from "../../assets/بروبوليس 1.webp";
import product6 from "../../assets/savon1.jpg";

const Products = () => {
  let productData = [
    {
      title: "معجون الأسنان",
      description:
        "معجون أسنان خال من الفلور و غري كاشط، يقوي مينا األسنان. مركبة مناليخضور بدون منتول يعطي إحساسا باالنتعاش الطبيعي. تركيبته املتكاملةمن العكرب و األلوي تطهر   ... ✨️",
      image: product1,
      price: 119,
      route: "/product1",
    },
    {
      title: "مزيل العرق",
      description:
        "يوفر مزيل العرق حماية فعالة طوال اليوم لا تحتوي تركيبته على أي كحولأو أملاح الألمنيوم القاسية عادة ما توجد في باقي مزيلات العرق💧.ويمكن استعمالها بعد الحلاقة تحت الإبط وإزالة الشعر بالشمع ✨️",
      image: product2,
      price: 112,
      route: "/product2",
    },
    {
      title: "واقي شمسي",
      description:
        "واقي الشمس الالوي ,دو الحماية القصوى يحمي البشرة من أثار الشمسالمضرة ومن الشيخوخة ,تركيبته الخفيفة والمرطبة تتعمق بسهولة دونالإلتصاق مقاوم للماء.",
      image: product3,
      price: 259,
      route: "/product3",
    },
    {
      title: "مرطب الشفاه",
      description:
        "واقي الشمس الالوي ,دو الحماية القصوى يحمي البشرة من أثار الشمسالمضرة ومن الشيخوخة ,تركيبته الخفيفة والمرطبة تتعمق بسهولة دونالإلتصاق مقاوم للماء.",
      image: product4,
      price: 55,
      route: "/product4",
    },
    {
      title: "بروبوليس كريم",
      description:
        "التعاون املشرتك لأللوي والعكرب مينحان لهذا املرهم الغني قدرة العناية الفعالة واملضادة للبكرتيا واملُرممة واملهدئة للتهيجات الجلدية البسيط فيضفون ...",
      image: product5,
      price: 299,
      route: "/product5",
    },
    {
      title: "صابون الأفوكادو ",
      description:
        "يعمل هذا الصابون الغني بزبدة األفوكادوعىل تنظيف جميع أنواع البرشة مبا فيها البشرةالحساسة،ُرطب * البرشة ويحافظ عىل شبابها...",
      image: product6,
      price: 69,
      route: "/product6",
    },
  ];
  return (
    <div className="product" id="Product">
      <div className="container main-section">
        {productData.map((e) => {
          return (
            <div className="box">
              <img src={e.image} alt="" />
              <div className="text">
                <h2>{e.title}</h2>
                <p>{e.description}</p>
              </div>
              <div className="btns">
                <a className="price" href={e.route}>
                  {e.price}
                </a>
                <a className="buy-btn" href={e.route}>
                  Buy Now
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Products;
